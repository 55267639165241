/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomersData } from '../models/CustomersData';
import type { Event } from '../models/Event';
import type { ImportRequest } from '../models/ImportRequest';
import type { ImportResponse } from '../models/ImportResponse';
import type { InvoicesData } from '../models/InvoicesData';
import type { Plugin } from '../models/Plugin';
import type { PluginExecute } from '../models/PluginExecute';
import type { PluginGetResult } from '../models/PluginGetResult';
import type { PluginSetEnabled } from '../models/PluginSetEnabled';
import type { PluginSetOptions } from '../models/PluginSetOptions';
import type { SubscriptionsData } from '../models/SubscriptionsData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PluginsService {

    /**
     * @returns PluginGetResult Success
     * @throws ApiError
     */
    public static getV1Plugins({
take,
skip,
search,
sortBy,
sortDesc,
}: {
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<PluginGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plugins',
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Plugin Success
     * @throws ApiError
     */
    public static getV1Plugins1({
id,
}: {
id: number,
}): CancelablePromise<Plugin> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plugins/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Plugin Success
     * @throws ApiError
     */
    public static getV1Plugins2({
key,
}: {
key: string,
}): CancelablePromise<Plugin> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/plugins/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns Plugin Success
     * @throws ApiError
     */
    public static putV1PluginsEnabled({
requestBody,
}: {
requestBody?: PluginSetEnabled,
}): CancelablePromise<Plugin> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/plugins/enabled',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Plugin Success
     * @throws ApiError
     */
    public static putV1PluginsOptions({
requestBody,
}: {
requestBody?: PluginSetOptions,
}): CancelablePromise<Plugin> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/plugins/options',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static postV1PluginsExecute({
requestBody,
}: {
requestBody?: PluginExecute,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plugins/execute',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static postV1PluginsCloudfactorySubscriptions({
requestBody,
}: {
requestBody?: SubscriptionsData,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plugins/cloudfactory/subscriptions',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns ImportResponse Success
     * @throws ApiError
     */
    public static postV1PluginsCsvimportTicket({
requestBody,
}: {
requestBody?: ImportRequest,
}): CancelablePromise<ImportResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plugins/csvimport/ticket',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static postV1PluginsEconomicCustomers({
requestBody,
}: {
requestBody?: CustomersData,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plugins/economic/customers',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static postV1PluginsEconomicInvoices({
requestBody,
}: {
requestBody?: InvoicesData,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/plugins/economic/invoices',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
