/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Role } from '../models/Role';
import type { RoleGetResult } from '../models/RoleGetResult';
import type { RolePermisison } from '../models/RolePermisison';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolesService {

    /**
     * @returns RoleGetResult Success
     * @throws ApiError
     */
    public static getV1Roles({
types,
take,
skip,
search,
sortBy,
sortDesc,
}: {
types?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<RoleGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/roles',
            query: {
                'Types': types,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Role Success
     * @throws ApiError
     */
    public static postV1Roles({
requestBody,
}: {
requestBody?: Role,
}): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/roles',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Role Success
     * @throws ApiError
     */
    public static putV1Roles({
requestBody,
}: {
requestBody?: Role,
}): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/roles',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Role Success
     * @throws ApiError
     */
    public static getV1Roles1({
id,
}: {
id: string,
}): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/roles/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Role Success
     * @throws ApiError
     */
    public static deleteV1Roles({
id,
}: {
id: string,
}): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/roles/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RolePermisison Success
     * @throws ApiError
     */
    public static getV1RolesPermission({
roleId,
}: {
roleId: string,
}): CancelablePromise<RolePermisison> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/roles/{roleId}/permission',
            path: {
                'roleId': roleId,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postV1RolesPermission({
roleId,
requestBody,
}: {
roleId: string,
requestBody?: RolePermisison,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/roles/{roleId}/permission',
            path: {
                'roleId': roleId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
