import { defineStore } from 'pinia'
import { useAuthStore } from './authStore'
import { type Filter, FiltersService } from '@/utils/api'
import type { FilterType } from '@/utils/enums'
import Logger from '@/utils/logger'

export const useFilterStore = defineStore('filter', () => {
  // 👉 Stores
  const authStore = useAuthStore()

  // 👉 Reactives
  const filterType = ref<undefined | FilterType>()
  const filterId = ref<undefined | number>()
  const filter = ref<undefined | Filter>()
  const filters = ref<Filter[]>([])
  const isDrawerShown = ref<boolean>(false)

  // 👉 Watch - Filter Type
  watch(filterType, async () => {
    filters.value = await fetch()
    filterId.value = loadFilterId()
  })

  // 👉 Watch - Filter Id
  watch(filterId, () => {
    filter.value = filterId.value ? filters.value.find(f => f.id === filterId.value) : undefined
    storeFilterId()
  })

  // 👉 Function - Set filter type
  async function setFilterType(type: undefined | FilterType) {
    filterType.value = type
  }

  // 👉 Function - Set filter id
  function setFilterId(id: undefined | number) {
    filterId.value = id
  }

  // 👉 Function - Set filter
  function setFilter(customFilter: undefined | Filter) {
    filterId.value = undefined

    nextTick(() => {
      filter.value = customFilter
    })
  }

  // 👉 Function - Show filter drawer
  function showDrawer() {
    isDrawerShown.value = true
  }

  // 👉 Function - Add
  async function add(addedFilter: Filter): Promise<undefined | Filter> {
    try {
      const model = await FiltersService.postV1Filters({ requestBody: addedFilter })

      filters.value = await fetch()
      filterId.value = model.id

      return model
    }
    catch (e) {
      Logger.error(e)
    }
  }

  // 👉 Function - Edit
  async function edit(editedFilter: Filter): Promise<undefined | Filter> {
    try {
      const model = await FiltersService.putV1Filters({ requestBody: editedFilter })

      filters.value = await fetch()
      filterId.value = model.id

      return model
    }
    catch (e) {
      Logger.error(e)
    }
  }

  // 👉 Function - Remove
  async function remove(id: number): Promise<void> {
    try {
      await FiltersService.deleteV1Filters({ id })

      filters.value = await fetch()
      filterId.value = undefined
    }
    catch (e) {
      Logger.error(e)
    }
  }

  // 👉 Function - Fetch
  async function fetch(): Promise<Filter[]> {
    try {
      const employeeId = authStore.userData?.employeeId

      const response = (employeeId && filterType.value)
        ? await FiltersService.getV1Filters({
          type: filterType.value,
          employeeId,
          take: 1000,
        })
        : { items: [], totalCount: 0 }

      return response.items ?? []
    }
    catch (error) {
      Logger.error(error)
    }

    return []
  }

  // 👉 Function - Load filterId from localStorage
  function loadFilterId(): undefined | number {
    const employeeId = authStore.userData?.employeeId
    if (employeeId && (filterType.value)) {
      const localStorageFilterKey = getFilterIdKey(filterType.value, employeeId)
      const storedFilterId = localStorage.getItem(localStorageFilterKey)

      if (storedFilterId !== null)
        return parseInt(storedFilterId, 10)
    }
  }

  // 👉 Function - Store filterId in localStorage
  function storeFilterId() {
    const employeeId = authStore.userData?.employeeId
    if (employeeId && (filterType.value)) {
      const localStorageFilterKey = getFilterIdKey(filterType.value, employeeId)

      if (filterId.value)
        localStorage.setItem(localStorageFilterKey, filterId.value.toString())
      else
        localStorage.removeItem(localStorageFilterKey)
    }
  }

  // 👉 Function - Get filter key
  function getFilterIdKey(type: number, employeeId: number) {
    return `${type}-${employeeId}-filter-id`
  }

  return {
    filterType,
    filterId,
    filter,
    filters,
    isDrawerShown,
    setFilterType,
    setFilterId,
    setFilter,
    showDrawer,
    add,
    edit,
    remove,
  }
})
