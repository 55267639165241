/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event } from '../models/Event';
import type { EventGetResult } from '../models/EventGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * @returns EventGetResult Success
     * @throws ApiError
     */
    public static getV1Events({
type,
take,
skip,
search,
sortBy,
sortDesc,
}: {
type?: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<EventGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/events',
            query: {
                'Type': type,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static postV1Events({
requestBody,
}: {
requestBody?: Event,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/events',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static putV1Events({
requestBody,
}: {
requestBody?: Event,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/events',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static getV1Events1({
id,
}: {
id: number,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/events/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static deleteV1Events({
id,
}: {
id: number,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/events/{id}',
            path: {
                'id': id,
            },
        });
    }

}
