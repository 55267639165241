﻿/**
 * Logger.
 * */
export default class Logger {
  public static info(message: string, ...optionalParams: any[]) {
    console.info(message, optionalParams)
  }

  public static error(error: unknown | string, ...optionalParams: any[]) {
    console.log(error, optionalParams)
  }

  public static debug(message: string, ...optionalParams: any[]) {
    console.debug(message, optionalParams)
  }
}
