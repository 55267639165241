/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Contact } from '../models/Contact';
import type { ContactListItemGetResult } from '../models/ContactListItemGetResult';
import type { ContactLookupItemGetResult } from '../models/ContactLookupItemGetResult';
import type { ContactReference } from '../models/ContactReference';
import type { ContactReferenceGetResult } from '../models/ContactReferenceGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContactsService {

    /**
     * @returns ContactListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Contacts({
customerId,
customerIds,
types,
statuses,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
customerIds?: Array<number>,
types?: Array<number>,
statuses?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<ContactListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/contacts',
            query: {
                'CustomerId': customerId,
                'CustomerIds': customerIds,
                'Types': types,
                'Statuses': statuses,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Contact Success
     * @throws ApiError
     */
    public static postV1Contacts({
requestBody,
}: {
requestBody?: Contact,
}): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/contacts',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Contact Success
     * @throws ApiError
     */
    public static putV1Contacts({
requestBody,
}: {
requestBody?: Contact,
}): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/contacts',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns ContactLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1ContactsLookup({
customerId,
types,
statuses,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
types?: Array<number>,
statuses?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<ContactLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/contacts/lookup',
            query: {
                'CustomerId': customerId,
                'Types': types,
                'Statuses': statuses,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Contact Success
     * @throws ApiError
     */
    public static getV1Contacts1({
id,
}: {
id: number,
}): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/contacts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Contact Success
     * @throws ApiError
     */
    public static deleteV1Contacts({
id,
}: {
id: number,
}): CancelablePromise<Contact> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/contacts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ContactReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1ContactsReferences({
contactId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
contactId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<ContactReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/contacts/{contactId}/references',
            path: {
                'contactId': contactId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns ContactReference Success
     * @throws ApiError
     */
    public static getV1ContactsReferences1({
id,
}: {
id: number,
}): CancelablePromise<ContactReference> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/contacts/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ContactReference Success
     * @throws ApiError
     */
    public static deleteV1ContactsReferences({
id,
}: {
id: number,
}): CancelablePromise<ContactReference> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/contacts/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ContactReference Success
     * @throws ApiError
     */
    public static postV1ContactsReferences({
requestBody,
}: {
requestBody?: ContactReference,
}): CancelablePromise<ContactReference> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/contacts/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns ContactReference Success
     * @throws ApiError
     */
    public static putV1ContactsReferences({
requestBody,
}: {
requestBody?: ContactReference,
}): CancelablePromise<ContactReference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/contacts/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
