import type { UserData } from './api'

/**
 * Determine if user is an employee
 * @param userData
 * @returns
 */
export const isEmployee = (userData?: UserData): boolean => {
  return (userData?.employeeId !== undefined) && (userData?.employeeId !== null)
}

/**
 * Determine if user is a contact
 * @param userData
 * @returns
 */
export const isContact = (userData?: UserData): boolean => {
  return (userData?.contactId !== undefined) && (userData?.contactId !== null)
}
