/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Setting } from '../models/Setting';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SettingsService {

    /**
     * @returns Setting Success
     * @throws ApiError
     */
    public static getV1Settings({
id,
}: {
id: number,
}): CancelablePromise<Setting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/settings/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Setting Success
     * @throws ApiError
     */
    public static deleteV1Settings({
id,
}: {
id: number,
}): CancelablePromise<Setting> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/settings/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Setting Success
     * @throws ApiError
     */
    public static getV1Settings1({
key,
}: {
key: string,
}): CancelablePromise<Setting> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/settings/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns Setting Success
     * @throws ApiError
     */
    public static postV1Settings({
requestBody,
}: {
requestBody?: Setting,
}): CancelablePromise<Setting> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/settings',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Setting Success
     * @throws ApiError
     */
    public static putV1Settings({
requestBody,
}: {
requestBody?: Setting,
}): CancelablePromise<Setting> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/settings',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
