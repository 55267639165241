/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event } from '../models/Event';
import type { Voucher } from '../models/Voucher';
import type { VoucherListItemGetResult } from '../models/VoucherListItemGetResult';
import type { VoucherLookupItemGetResult } from '../models/VoucherLookupItemGetResult';
import type { VoucherRenewal } from '../models/VoucherRenewal';
import type { VoucherRenewalGetResult } from '../models/VoucherRenewalGetResult';
import type { VoucherRenewals } from '../models/VoucherRenewals';
import type { VoucherType } from '../models/VoucherType';
import type { VoucherTypeListItemGetResult } from '../models/VoucherTypeListItemGetResult';
import type { VoucherTypeLookupItemGetResult } from '../models/VoucherTypeLookupItemGetResult';
import type { VoucherTypeReference } from '../models/VoucherTypeReference';
import type { VoucherTypeReferenceGetResult } from '../models/VoucherTypeReferenceGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VouchersService {

    /**
     * @returns VoucherListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Vouchers({
customerId,
customerIds,
typeIds,
isAutoRenewal,
isExpired,
includeSummary,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
customerIds?: Array<number>,
typeIds?: Array<number>,
isAutoRenewal?: boolean,
isExpired?: boolean,
includeSummary?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<VoucherListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers',
            query: {
                'CustomerId': customerId,
                'CustomerIds': customerIds,
                'TypeIds': typeIds,
                'IsAutoRenewal': isAutoRenewal,
                'IsExpired': isExpired,
                'IncludeSummary': includeSummary,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Voucher Success
     * @throws ApiError
     */
    public static postV1Vouchers({
requestBody,
}: {
requestBody?: Voucher,
}): CancelablePromise<Voucher> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/vouchers',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Voucher Success
     * @throws ApiError
     */
    public static putV1Vouchers({
requestBody,
}: {
requestBody?: Voucher,
}): CancelablePromise<Voucher> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/vouchers',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns VoucherLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1VouchersLookup({
customerId,
excludeExpired,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
excludeExpired?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<VoucherLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers/lookup',
            query: {
                'CustomerId': customerId,
                'ExcludeExpired': excludeExpired,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Voucher Success
     * @throws ApiError
     */
    public static getV1Vouchers1({
id,
includeType,
includeCustomer,
includeRenewals,
includeSummary,
}: {
id: number,
includeType?: boolean,
includeCustomer?: boolean,
includeRenewals?: boolean,
includeSummary?: boolean,
}): CancelablePromise<Voucher> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers/{id}',
            path: {
                'id': id,
            },
            query: {
                'IncludeType': includeType,
                'IncludeCustomer': includeCustomer,
                'IncludeRenewals': includeRenewals,
                'IncludeSummary': includeSummary,
            },
        });
    }

    /**
     * @returns Voucher Success
     * @throws ApiError
     */
    public static deleteV1Vouchers({
id,
}: {
id: number,
}): CancelablePromise<Voucher> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/vouchers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VoucherTypeListItemGetResult Success
     * @throws ApiError
     */
    public static getV1VouchersTypes({
statuses,
take,
skip,
search,
sortBy,
sortDesc,
}: {
statuses?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<VoucherTypeListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers/types',
            query: {
                'Statuses': statuses,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns VoucherType Success
     * @throws ApiError
     */
    public static postV1VouchersTypes({
requestBody,
}: {
requestBody?: VoucherType,
}): CancelablePromise<VoucherType> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/vouchers/types',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns VoucherType Success
     * @throws ApiError
     */
    public static putV1VouchersTypes({
requestBody,
}: {
requestBody?: VoucherType,
}): CancelablePromise<VoucherType> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/vouchers/types',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns VoucherTypeLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1VouchersTypesLookup({
statuses,
take,
skip,
search,
sortBy,
sortDesc,
}: {
statuses?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<VoucherTypeLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers/types/lookup',
            query: {
                'Statuses': statuses,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns VoucherType Success
     * @throws ApiError
     */
    public static getV1VouchersTypes1({
id,
includeReferences,
}: {
id: number,
includeReferences?: boolean,
}): CancelablePromise<VoucherType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers/types/{id}',
            path: {
                'id': id,
            },
            query: {
                'IncludeReferences': includeReferences,
            },
        });
    }

    /**
     * @returns VoucherType Success
     * @throws ApiError
     */
    public static deleteV1VouchersTypes({
id,
}: {
id: number,
}): CancelablePromise<VoucherType> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/vouchers/types/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VoucherTypeReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1VouchersTypesReferences({
voucherTypeId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
voucherTypeId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<VoucherTypeReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers/types/{voucherTypeId}/references',
            path: {
                'voucherTypeId': voucherTypeId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns VoucherTypeReference Success
     * @throws ApiError
     */
    public static getV1VouchersTypesReferences1({
id,
}: {
id: number,
}): CancelablePromise<VoucherTypeReference> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers/types/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VoucherTypeReference Success
     * @throws ApiError
     */
    public static deleteV1VouchersTypesReferences({
id,
}: {
id: number,
}): CancelablePromise<VoucherTypeReference> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/vouchers/types/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VoucherTypeReference Success
     * @throws ApiError
     */
    public static postV1VouchersTypesReferences({
requestBody,
}: {
requestBody?: VoucherTypeReference,
}): CancelablePromise<VoucherTypeReference> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/vouchers/types/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns VoucherTypeReference Success
     * @throws ApiError
     */
    public static putV1VouchersTypesReferences({
requestBody,
}: {
requestBody?: VoucherTypeReference,
}): CancelablePromise<VoucherTypeReference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/vouchers/types/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns VoucherRenewalGetResult Success
     * @throws ApiError
     */
    public static getV1VouchersRenewals({
voucherId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
voucherId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<VoucherRenewalGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers/{voucherId}/renewals',
            path: {
                'voucherId': voucherId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns VoucherRenewal Success
     * @throws ApiError
     */
    public static getV1VouchersRenewals1({
id,
}: {
id: number,
}): CancelablePromise<VoucherRenewal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/vouchers/renewals/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VoucherRenewal Success
     * @throws ApiError
     */
    public static deleteV1VouchersRenewals({
id,
}: {
id: number,
}): CancelablePromise<VoucherRenewal> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/vouchers/renewals/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns VoucherRenewal Success
     * @throws ApiError
     */
    public static postV1VouchersRenewals({
requestBody,
}: {
requestBody?: VoucherRenewal,
}): CancelablePromise<VoucherRenewal> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/vouchers/renewals',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns VoucherRenewal Success
     * @throws ApiError
     */
    public static putV1VouchersRenewals({
requestBody,
}: {
requestBody?: VoucherRenewal,
}): CancelablePromise<VoucherRenewal> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/vouchers/renewals',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static postV1VouchersRenewalsMany({
requestBody,
}: {
requestBody?: VoucherRenewals,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/vouchers/renewals/many',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
