/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import { router } from '@/router/router'

import { defaultOptions } from '@/utils/dx/defaultOptions'
import { abilitiesPlugin } from '@casl/vue'

import '@core/scss/template/index.scss'

import '@styles/styles.scss'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { useAuthStore } from './stores/authStore'

// Import DevExtreme CSS.
import 'devextreme/dist/css/dx.light.css'
import { baseZIndex } from 'devextreme/ui/overlay'
import { ability } from './abilities/appAbility'
import { registerPluginComponents } from './plugins/app/plugins'
import { OpenAPI } from './utils/api'
import { initializeInterceptors } from './utils/axios'
import { loadConfig } from './utils/config'

// ?? import 'devextreme/dist/css/dx.material.blue.light.css'

// BUGFIX - Set initial OpenApi Base - for some reason first load (/) failes...
OpenAPI.BASE = 'https://api.smartmsp.dk'

baseZIndex(9999)

loadFonts()

// Set DevExtreme default options.
defaultOptions()

// Retrieve all i18n messages
const messages = Object.fromEntries(
  Object.entries(
    import.meta.glob<{ default: any }>('./locales/*.json', { eager: true }))
    .map(([key, value]) => [key.slice(10, -5), value.default]),
)

// Create i18n
const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages,
})

// Create pinia
const pinia = createPinia()

// Set pinia persisted plugin
pinia.use(piniaPluginPersistedstate)

// Create vue app
const app = createApp(App)

// Use plugins
app.use(i18n)
app.use(pinia)
app.use(vuetify)
app.use(layoutsPlugin)
app.use(abilitiesPlugin, ability)

/* ?? REMOVE app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
}) */

// Register App plugins
registerPluginComponents(app)

// Get auth store.
const authStore = useAuthStore()

// Update CASL user abilities
ability.update(authStore.userAbilities)

// Load config
loadConfig()
  .then(config => {
    // Set api base
    OpenAPI.BASE = config?.apiUrl ?? import.meta.env.VITE_API_URL

    // Set initial API token
    OpenAPI.TOKEN = authStore.token

    // Handle auth store changes
    authStore.$subscribe((mutation, state) => {
      // Update API access token (JWT)
      OpenAPI.TOKEN = state._token

      // Update CASL user abilities
      ability.update(state._userAbilities)

      // Has user logged out?
      if ((state._token === undefined) && !window.location.href?.endsWith('/login'))
        window.location.href = '/login'
    })

    // Initialize axios interceptors
    initializeInterceptors(router)

    // Use router
    app.use(router)

    // Mount vue app
    app.mount('#app')
  })
