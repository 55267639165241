{
  "Dashboard": "Dashboard",
  "Tickets": "Tickets",
  "Projects": "Projects",
  "Knowledgebase": "Knowledgebase",
  "Business": "Business",
  "Customers": "Customers",
  "Contacts": "Contacts",
  "Invoices": "Invoices",
  "Vouchers": "Vouchers",
  "Subscriptions": "Subscriptions",
  "Administration": "Administration",
  "Employees": "Employees",
  "Setup": "Setup",
  "Add ticket": "Add ticket",
  "$vuetify": {
  }
}
