{
  "Dashboard": "Dashboard",
  "Tickets": "Supportsager",
  "Projects": "Projekter",
  "Knowledgebase": "Vidensbase",
  "Business": "Forretning",
  "Customers": "Kunder",
  "Contacts": "Kontakter",
  "Invoices": "Fakturaer",
  "Vouchers": "Klippekort",
  "Subscriptions": "Abonnementer",
  "Administration": "Administration",
  "Employees": "Medarbejder",
  "Setup": "Opsætning",
  "Add ticket": "Tilføj supportsag",
  "$vuetify": {
  }
}
