/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Option } from '../models/Option';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OptionsService {

    /**
     * @returns Option Success
     * @throws ApiError
     */
    public static getV1Options({
id,
}: {
id: number,
}): CancelablePromise<Option> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/options/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Option Success
     * @throws ApiError
     */
    public static deleteV1Options({
id,
}: {
id: number,
}): CancelablePromise<Option> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/options/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Option Success
     * @throws ApiError
     */
    public static getV1Options1({
key,
}: {
key: string,
}): CancelablePromise<Option> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/options/{key}',
            path: {
                'key': key,
            },
        });
    }

    /**
     * @returns Option Success
     * @throws ApiError
     */
    public static postV1Options({
requestBody,
}: {
requestBody?: Option,
}): CancelablePromise<Option> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/options',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Option Success
     * @throws ApiError
     */
    public static putV1Options({
requestBody,
}: {
requestBody?: Option,
}): CancelablePromise<Option> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/options',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
