import type { App, Component } from 'vue'
import { defineAsyncComponent } from 'vue'

let componentId = 1

// 👉 Function - Register plugin components.
function getComponentName() {
  return `AppPlugin${componentId++}`
}

// 👉 Interface - PluginComponent
export interface PluginComponent {
  name: string
  pluginKey: string
  location: string
  component: Component
}

// 👉 All plugin components
const pluginComponents: PluginComponent[] = [

  // 👉 Cloud Factory plugin
  { name: getComponentName(), pluginKey: 'CloudFactory', location: 'Subscriptions.Menu', component: defineAsyncComponent(() => import('@/plugins/app/cloudFactory/components/MenuSubscriptions.vue')) },
  { name: getComponentName(), pluginKey: 'CloudFactory', location: 'Subscriptions.Dialog', component: defineAsyncComponent(() => import('@/plugins/app/cloudFactory/components/DialogSubscriptions.vue')) },
  { name: getComponentName(), pluginKey: 'CloudFactory', location: 'Customer.Detail', component: defineAsyncComponent(() => import('@/plugins/app/cloudFactory/components/CardCloudFactoryCustomer.vue')) },
  { name: getComponentName(), pluginKey: 'CloudFactory', location: 'Subscription.Detail', component: defineAsyncComponent(() => import('@/plugins/app/cloudFactory/components/CardCloudFactorySubscription.vue')) },
  { name: getComponentName(), pluginKey: 'CloudFactory', location: 'SubscriptionType.Detail', component: defineAsyncComponent(() => import('@/plugins/app/cloudFactory/components/CardCloudFactoryProduct.vue')) },
  { name: getComponentName(), pluginKey: 'CloudFactory', location: 'Setup.Plugin', component: defineAsyncComponent(() => import('@/plugins/app/cloudFactory/components/PluginSetupCloudFactory.vue')) },
  { name: getComponentName(), pluginKey: 'CloudFactory', location: 'Setup.Plugin.Help', component: defineAsyncComponent(() => import('@/plugins/app/cloudFactory/components/PluginSetupHelpCloudFactory.vue')) },

  // 👉 E-conomic plugin
  { name: getComponentName(), pluginKey: 'Economic', location: 'Customers.Menu', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/MenuCustomers.vue')) },
  { name: getComponentName(), pluginKey: 'Economic', location: 'Customers.Dialog', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/DialogCustomers.vue')) },
  { name: getComponentName(), pluginKey: 'Economic', location: 'Invoices.Menu', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/MenuInvoices.vue')) },
  { name: getComponentName(), pluginKey: 'Economic', location: 'Invoices.Dialog', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/DialogInvoices.vue')) },
  { name: getComponentName(), pluginKey: 'Economic', location: 'Customer.Detail', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/CardEconomicCustomer.vue')) },
  { name: getComponentName(), pluginKey: 'Economic', location: 'SubscriptionType.Detail', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/CardEconomicProduct.vue')) },
  { name: getComponentName(), pluginKey: 'Economic', location: 'VoucherType.Detail', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/CardEconomicProduct.vue')) },
  { name: getComponentName(), pluginKey: 'Economic', location: 'TimeEntryType.Detail', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/CardEconomicProduct.vue')) },
  { name: getComponentName(), pluginKey: 'Economic', location: 'Setup.Plugin', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/PluginSetupEconomic.vue')) },
  { name: getComponentName(), pluginKey: 'Economic', location: 'Setup.Plugin.Help', component: defineAsyncComponent(() => import('@/plugins/app/economic/components/PluginSetupHelpEconomic.vue')) },

  // 👉 Spiceworks plugin
  // TODO - { pluginKey: 'Spiceworks', componentKey: 'Setup', component: PluginSetupSpiceworks },

  // 👉 SmartMSP-Backup plugin
  // TODO - { pluginKey: 'SmartMSP-Backup', componentKey: 'Setup', component: PluginSetupSmartMspBackup },

  // 👉 Office365 plugin
  { name: getComponentName(), pluginKey: 'Office365', location: 'Setup.Plugin', component: defineAsyncComponent(() => import('@/plugins/app/office365/components/PluginSetupOffice365.vue')) },
  { name: getComponentName(), pluginKey: 'Office365', location: 'Setup.Plugin.Help', component: defineAsyncComponent(() => import('@/plugins/app/office365/components/PluginSetupHelpOffice365.vue')) },

  // 👉 Imap plugin
  // TODO - { pluginKey: 'Imap', componentKey: 'Setup', component: PluginSetupImap },

  // 👉 CsvImport plugin
  { name: getComponentName(), pluginKey: 'CsvImport', location: 'Setup.Plugin', component: defineAsyncComponent(() => import('@/plugins/app/csvImport/components/PluginSetupCsvImport.vue')) },
  { name: getComponentName(), pluginKey: 'CsvImport', location: 'Setup.Plugin.Help', component: defineAsyncComponent(() => import('@/plugins/app/csvImport/components/PluginSetupHelpCsvImport.vue')) },

]

// 👉 Function - Get plugin components.
export const getPluginComponents = (location?: string): PluginComponent[] => {
  return location ? pluginComponents.filter(c => c.location === location) : pluginComponents
}

// 👉 Function - Get plugin component.
export const getPluginComponent = (pluginKey: string, location: string): undefined | PluginComponent => {
  return getPluginComponents(location)?.find(c => c.pluginKey === pluginKey)
}

// 👉 Function - Register plugin components.
export const registerPluginComponents = (app: App<Element>) => {
  pluginComponents.forEach(c => {
    app.component(c.name, c.component)
  })
}
