/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Role } from '../models/Role';
import type { UserListItemGetResult } from '../models/UserListItemGetResult';
import type { UserLookupItemGetResult } from '../models/UserLookupItemGetResult';
import type { UserPassword } from '../models/UserPassword';
import type { UserRole } from '../models/UserRole';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @returns UserListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Users({
excludeContacts,
excludeLockedout,
take,
skip,
search,
sortBy,
sortDesc,
}: {
excludeContacts?: boolean,
excludeLockedout?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<UserListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users',
            query: {
                'ExcludeContacts': excludeContacts,
                'ExcludeLockedout': excludeLockedout,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns UserLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1UsersLookup({
excludeContacts,
excludeLockedout,
take,
skip,
search,
sortBy,
sortDesc,
}: {
excludeContacts?: boolean,
excludeLockedout?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<UserLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/lookup',
            query: {
                'ExcludeContacts': excludeContacts,
                'ExcludeLockedout': excludeLockedout,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Role Success
     * @throws ApiError
     */
    public static getV1UsersRole({
userId,
}: {
userId: string,
}): CancelablePromise<Role> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/{userId}/role',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postV1UsersRole({
userId,
requestBody,
}: {
userId: string,
requestBody?: UserRole,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/{userId}/role',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static postV1UsersPassword({
userId,
requestBody,
}: {
userId: string,
requestBody?: UserPassword,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/{userId}/password',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
