/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { File } from '../models/File';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FilesService {

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getV1Files({
id,
}: {
id: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/files/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteV1Files({
id,
}: {
id: string,
}): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/files/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getV1FilesDownload({
id,
}: {
id: string,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/files/download/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns File Success
     * @throws ApiError
     */
    public static postV1FilesUpload({
formData,
}: {
formData?: {
ContentType?: string;
ContentDisposition?: string;
Headers?: Record<string, Array<string>>;
Length?: number;
Name?: string;
FileName?: string;
},
}): CancelablePromise<File> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/files/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
