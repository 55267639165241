import { defineStore } from 'pinia'
import type { UserAbility } from '@/abilities/appAbility'
import type { UserData } from '@/utils/api'
import { decode } from '@/utils/jwt'

// TODO - ?? We need to do something about the localStorage of abilities....

export const initialAbility: UserAbility[] = []

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      _token: undefined as string | undefined,
      _userData: undefined as UserData | undefined,
      _userAbilities: initialAbility as UserAbility[],
    }
  },
  getters: {
    token(): string | undefined {
      return this._token
    },
    userData(): UserData | undefined {
      return this._userData
    },
    userAbilities(): UserAbility[] {
      return this._userAbilities
    },
    isLoggedIn(): boolean {
      return this._token != null
    },
  },
  actions: {
    login(token: string, userData: UserData) {
      let userAbilities = initialAbility

      // Decode JWT token
      const decodedToken = decode(token)

      // Get permissions (CASL abilities)
      if (decodedToken?.Permission)
        userAbilities = JSON.parse(decodedToken.Permission) as UserAbility[]

      this._token = token
      this._userData = userData
      this._userAbilities = userAbilities ?? initialAbility
    },
    logout() {
      this._token = undefined
      this._userData = undefined
      this._userAbilities = initialAbility
    },
    setUserData(userData: UserData) {
      this._userData = userData
    },
  },
  persist: {
    storage: localStorage,
  },
})
