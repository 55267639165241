/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Project } from '../models/Project';
import type { ProjectActivity } from '../models/ProjectActivity';
import type { ProjectActivityGetResult } from '../models/ProjectActivityGetResult';
import type { ProjectAttachment } from '../models/ProjectAttachment';
import type { ProjectAttachmentGetResult } from '../models/ProjectAttachmentGetResult';
import type { ProjectListItemGetResult } from '../models/ProjectListItemGetResult';
import type { ProjectReference } from '../models/ProjectReference';
import type { ProjectReferenceGetResult } from '../models/ProjectReferenceGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectsService {

    /**
     * @returns ProjectListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Projects({
customerId,
customerIds,
contactId,
contactIds,
employeeId,
employeeIds,
types,
statuses,
priorites,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
customerIds?: Array<number>,
contactId?: number,
contactIds?: Array<number>,
employeeId?: number,
employeeIds?: Array<number>,
types?: Array<number>,
statuses?: Array<number>,
priorites?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<ProjectListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/projects',
            query: {
                'CustomerId': customerId,
                'CustomerIds': customerIds,
                'ContactId': contactId,
                'ContactIds': contactIds,
                'EmployeeId': employeeId,
                'EmployeeIds': employeeIds,
                'Types': types,
                'Statuses': statuses,
                'Priorites': priorites,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Project Success
     * @throws ApiError
     */
    public static postV1Projects({
notify,
requestBody,
}: {
notify?: boolean,
requestBody?: Project,
}): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/projects',
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Project Success
     * @throws ApiError
     */
    public static putV1Projects({
requestBody,
}: {
requestBody?: Project,
}): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/projects',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Project Success
     * @throws ApiError
     */
    public static getV1Projects1({
id,
}: {
id: number,
}): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/projects/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Project Success
     * @throws ApiError
     */
    public static deleteV1Projects({
id,
markAsDeleted,
}: {
id: number,
markAsDeleted?: boolean,
}): CancelablePromise<Project> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/projects/{id}',
            path: {
                'id': id,
            },
            query: {
                'markAsDeleted': markAsDeleted,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static putV1ProjectsMerge({
parentId,
childIds,
}: {
parentId?: number,
childIds?: string,
}): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/projects/merge',
            query: {
                'parentId': parentId,
                'childIds': childIds,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static putV1ProjectsMany({
ids,
}: {
ids?: string,
}): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/projects/many',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static deleteV1ProjectsMany({
ids,
markAsDeleted,
}: {
ids?: string,
markAsDeleted?: boolean,
}): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/projects/many',
            query: {
                'ids': ids,
                'markAsDeleted': markAsDeleted,
            },
        });
    }

    /**
     * @returns ProjectActivityGetResult Success
     * @throws ApiError
     */
    public static getV1ProjectsActivities({
projectId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
projectId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<ProjectActivityGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/projects/{projectId}/activities',
            path: {
                'projectId': projectId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns ProjectActivity Success
     * @throws ApiError
     */
    public static getV1ProjectsActivities1({
id,
}: {
id: number,
}): CancelablePromise<ProjectActivity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/projects/activities/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProjectActivity Success
     * @throws ApiError
     */
    public static deleteV1ProjectsActivities({
id,
}: {
id: number,
}): CancelablePromise<ProjectActivity> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/projects/activities/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProjectActivity Success
     * @throws ApiError
     */
    public static postV1ProjectsActivities({
notify,
requestBody,
}: {
notify?: boolean,
requestBody?: ProjectActivity,
}): CancelablePromise<ProjectActivity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/projects/activities',
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns ProjectActivity Success
     * @throws ApiError
     */
    public static putV1ProjectsActivities({
requestBody,
}: {
requestBody?: ProjectActivity,
}): CancelablePromise<ProjectActivity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/projects/activities',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns ProjectAttachmentGetResult Success
     * @throws ApiError
     */
    public static getV1ProjectsAttachments({
projectId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
projectId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<ProjectAttachmentGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/projects/{projectId}/attachments',
            path: {
                'projectId': projectId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns ProjectAttachment Success
     * @throws ApiError
     */
    public static getV1ProjectsAttachments1({
id,
}: {
id: string,
}): CancelablePromise<ProjectAttachment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/projects/attachments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProjectAttachment Success
     * @throws ApiError
     */
    public static deleteV1ProjectsAttachments({
id,
}: {
id: string,
}): CancelablePromise<ProjectAttachment> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/projects/attachments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProjectAttachment Success
     * @throws ApiError
     */
    public static postV1ProjectsAttachments({
notify,
requestBody,
}: {
notify?: boolean,
requestBody?: ProjectAttachment,
}): CancelablePromise<ProjectAttachment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/projects/attachments',
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns ProjectAttachment Success
     * @throws ApiError
     */
    public static putV1ProjectsAttachments({
requestBody,
}: {
requestBody?: ProjectAttachment,
}): CancelablePromise<ProjectAttachment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/projects/attachments',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns ProjectReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1ProjectsReferences({
projectId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
projectId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<ProjectReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/projects/{projectId}/references',
            path: {
                'projectId': projectId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns ProjectReference Success
     * @throws ApiError
     */
    public static getV1ProjectsReferences1({
id,
}: {
id: number,
}): CancelablePromise<ProjectReference> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/projects/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProjectReference Success
     * @throws ApiError
     */
    public static deleteV1ProjectsReferences({
id,
}: {
id: number,
}): CancelablePromise<ProjectReference> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/projects/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ProjectReference Success
     * @throws ApiError
     */
    public static postV1ProjectsReferences({
notify,
requestBody,
}: {
notify?: boolean,
requestBody?: ProjectReference,
}): CancelablePromise<ProjectReference> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/projects/references',
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns ProjectReference Success
     * @throws ApiError
     */
    public static putV1ProjectsReferences({
requestBody,
}: {
requestBody?: ProjectReference,
}): CancelablePromise<ProjectReference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/projects/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
