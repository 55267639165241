/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event } from '../models/Event';
import type { Subscription } from '../models/Subscription';
import type { SubscriptionListItemGetResult } from '../models/SubscriptionListItemGetResult';
import type { SubscriptionLookupItemGetResult } from '../models/SubscriptionLookupItemGetResult';
import type { SubscriptionPeriod } from '../models/SubscriptionPeriod';
import type { SubscriptionPeriodGetResult } from '../models/SubscriptionPeriodGetResult';
import type { SubscriptionPeriods } from '../models/SubscriptionPeriods';
import type { SubscriptionReference } from '../models/SubscriptionReference';
import type { SubscriptionReferenceGetResult } from '../models/SubscriptionReferenceGetResult';
import type { SubscriptionType } from '../models/SubscriptionType';
import type { SubscriptionTypeListItemGetResult } from '../models/SubscriptionTypeListItemGetResult';
import type { SubscriptionTypeLookupItemGetResult } from '../models/SubscriptionTypeLookupItemGetResult';
import type { SubscriptionTypeReference } from '../models/SubscriptionTypeReference';
import type { SubscriptionTypeReferenceGetResult } from '../models/SubscriptionTypeReferenceGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionsService {

    /**
     * @returns SubscriptionListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Subscriptions({
customerId,
customerIds,
typeIds,
isTerminated,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
customerIds?: Array<number>,
typeIds?: Array<number>,
isTerminated?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<SubscriptionListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions',
            query: {
                'CustomerId': customerId,
                'CustomerIds': customerIds,
                'TypeIds': typeIds,
                'IsTerminated': isTerminated,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Subscription Success
     * @throws ApiError
     */
    public static postV1Subscriptions({
requestBody,
}: {
requestBody?: Subscription,
}): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Subscription Success
     * @throws ApiError
     */
    public static putV1Subscriptions({
requestBody,
}: {
requestBody?: Subscription,
}): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/subscriptions',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns SubscriptionLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1SubscriptionsLookup({
customerId,
supportsTimeEntries,
excludeTerminated,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
supportsTimeEntries?: boolean,
excludeTerminated?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<SubscriptionLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/lookup',
            query: {
                'CustomerId': customerId,
                'SupportsTimeEntries': supportsTimeEntries,
                'ExcludeTerminated': excludeTerminated,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Subscription Success
     * @throws ApiError
     */
    public static getV1Subscriptions1({
id,
includeType,
includeCustomer,
includeCustomerReferences,
includeReferences,
includePeriods,
}: {
id: number,
includeType?: boolean,
includeCustomer?: boolean,
includeCustomerReferences?: boolean,
includeReferences?: boolean,
includePeriods?: boolean,
}): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/{id}',
            path: {
                'id': id,
            },
            query: {
                'IncludeType': includeType,
                'IncludeCustomer': includeCustomer,
                'IncludeCustomerReferences': includeCustomerReferences,
                'IncludeReferences': includeReferences,
                'IncludePeriods': includePeriods,
            },
        });
    }

    /**
     * @returns Subscription Success
     * @throws ApiError
     */
    public static deleteV1Subscriptions({
id,
}: {
id: number,
}): CancelablePromise<Subscription> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subscriptions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SubscriptionReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1SubscriptionsReferences({
subscriptionId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
subscriptionId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<SubscriptionReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/{subscriptionId}/references',
            path: {
                'subscriptionId': subscriptionId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns SubscriptionReference Success
     * @throws ApiError
     */
    public static getV1SubscriptionsReferences1({
id,
}: {
id: number,
}): CancelablePromise<SubscriptionReference> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SubscriptionReference Success
     * @throws ApiError
     */
    public static deleteV1SubscriptionsReferences({
id,
}: {
id: number,
}): CancelablePromise<SubscriptionReference> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subscriptions/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SubscriptionReference Success
     * @throws ApiError
     */
    public static postV1SubscriptionsReferences({
requestBody,
}: {
requestBody?: SubscriptionReference,
}): CancelablePromise<SubscriptionReference> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns SubscriptionReference Success
     * @throws ApiError
     */
    public static putV1SubscriptionsReferences({
requestBody,
}: {
requestBody?: SubscriptionReference,
}): CancelablePromise<SubscriptionReference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/subscriptions/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns SubscriptionTypeListItemGetResult Success
     * @throws ApiError
     */
    public static getV1SubscriptionsTypes({
statuses,
take,
skip,
search,
sortBy,
sortDesc,
}: {
statuses?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<SubscriptionTypeListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/types',
            query: {
                'Statuses': statuses,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns SubscriptionType Success
     * @throws ApiError
     */
    public static postV1SubscriptionsTypes({
requestBody,
}: {
requestBody?: SubscriptionType,
}): CancelablePromise<SubscriptionType> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions/types',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns SubscriptionType Success
     * @throws ApiError
     */
    public static putV1SubscriptionsTypes({
requestBody,
}: {
requestBody?: SubscriptionType,
}): CancelablePromise<SubscriptionType> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/subscriptions/types',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns SubscriptionTypeLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1SubscriptionsTypesLookup({
statuses,
take,
skip,
search,
sortBy,
sortDesc,
}: {
statuses?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<SubscriptionTypeLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/types/lookup',
            query: {
                'Statuses': statuses,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns SubscriptionType Success
     * @throws ApiError
     */
    public static getV1SubscriptionsTypes1({
id,
includeReferences,
}: {
id: number,
includeReferences?: boolean,
}): CancelablePromise<SubscriptionType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/types/{id}',
            path: {
                'id': id,
            },
            query: {
                'IncludeReferences': includeReferences,
            },
        });
    }

    /**
     * @returns SubscriptionType Success
     * @throws ApiError
     */
    public static deleteV1SubscriptionsTypes({
id,
}: {
id: number,
}): CancelablePromise<SubscriptionType> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subscriptions/types/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SubscriptionTypeReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1SubscriptionsTypesReferences({
subscriptionTypeId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
subscriptionTypeId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<SubscriptionTypeReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/types/{subscriptionTypeId}/references',
            path: {
                'subscriptionTypeId': subscriptionTypeId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns SubscriptionTypeReference Success
     * @throws ApiError
     */
    public static getV1SubscriptionsTypesReferences1({
id,
}: {
id: number,
}): CancelablePromise<SubscriptionTypeReference> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/types/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SubscriptionTypeReference Success
     * @throws ApiError
     */
    public static deleteV1SubscriptionsTypesReferences({
id,
}: {
id: number,
}): CancelablePromise<SubscriptionTypeReference> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subscriptions/types/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SubscriptionTypeReference Success
     * @throws ApiError
     */
    public static postV1SubscriptionsTypesReferences({
requestBody,
}: {
requestBody?: SubscriptionTypeReference,
}): CancelablePromise<SubscriptionTypeReference> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions/types/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns SubscriptionTypeReference Success
     * @throws ApiError
     */
    public static putV1SubscriptionsTypesReferences({
requestBody,
}: {
requestBody?: SubscriptionTypeReference,
}): CancelablePromise<SubscriptionTypeReference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/subscriptions/types/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns SubscriptionPeriodGetResult Success
     * @throws ApiError
     */
    public static getV1SubscriptionsPeriods({
subscriptionId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
subscriptionId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<SubscriptionPeriodGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/{subscriptionId}/periods',
            path: {
                'subscriptionId': subscriptionId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns SubscriptionPeriod Success
     * @throws ApiError
     */
    public static getV1SubscriptionsPeriods1({
id,
}: {
id: number,
}): CancelablePromise<SubscriptionPeriod> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/subscriptions/periods/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SubscriptionPeriod Success
     * @throws ApiError
     */
    public static deleteV1SubscriptionsPeriods({
id,
}: {
id: number,
}): CancelablePromise<SubscriptionPeriod> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/subscriptions/periods/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns SubscriptionPeriod Success
     * @throws ApiError
     */
    public static postV1SubscriptionsPeriods({
requestBody,
}: {
requestBody?: SubscriptionPeriod,
}): CancelablePromise<SubscriptionPeriod> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions/periods',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns SubscriptionPeriod Success
     * @throws ApiError
     */
    public static putV1SubscriptionsPeriods({
requestBody,
}: {
requestBody?: SubscriptionPeriod,
}): CancelablePromise<SubscriptionPeriod> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/subscriptions/periods',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static postV1SubscriptionsPeriodsMany({
requestBody,
}: {
requestBody?: SubscriptionPeriods,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/subscriptions/periods/many',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
