import type { AbilityClass } from '@casl/ability'
import { PureAbility, createMongoAbility } from '@casl/ability'

export type Actions = 'read' | 'add' | 'edit' | 'delete' | 'manage'

export type Subjects = 'Dashboard' | 'Ticket' | 'Project' | 'Invoice' | 'Knowledgebase' | 'Customer' | 'Contact' | 'Subscription' | 'SubscriptionType' | 'Voucher' | 'VoucherType' | 'TimeEntry' | 'TimeEntryType' | 'Employee' | 'Setup' | 'all'

export type AppAbility = PureAbility<[Actions, Subjects]>

export interface UserAbility {
  action: Actions
  subject: Subjects
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAbility = PureAbility as AbilityClass<AppAbility>

// Create CASL ability
export const ability = createMongoAbility<[Actions, Subjects]>()
