/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Template } from '../models/Template';
import type { TemplateGetResult } from '../models/TemplateGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TemplatesService {

    /**
     * @returns TemplateGetResult Success
     * @throws ApiError
     */
    public static getV1Templates({
take,
skip,
search,
sortBy,
sortDesc,
}: {
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TemplateGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/templates',
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Template Success
     * @throws ApiError
     */
    public static postV1Templates({
requestBody,
}: {
requestBody?: Template,
}): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/templates',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Template Success
     * @throws ApiError
     */
    public static putV1Templates({
requestBody,
}: {
requestBody?: Template,
}): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/templates',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getV1TemplatesTags({
key,
}: {
key?: string,
}): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/templates/tags',
            query: {
                'key': key,
            },
        });
    }

    /**
     * @returns Template Success
     * @throws ApiError
     */
    public static getV1Templates1({
id,
}: {
id: number,
}): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/templates/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Template Success
     * @throws ApiError
     */
    public static deleteV1Templates({
id,
}: {
id: number,
}): CancelablePromise<Template> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/templates/{id}',
            path: {
                'id': id,
            },
        });
    }

}
