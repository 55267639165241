/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Filter } from '../models/Filter';
import type { FilterGetResult } from '../models/FilterGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FiltersService {

    /**
     * @returns FilterGetResult Success
     * @throws ApiError
     */
    public static getV1Filters({
type,
employeeId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
type?: number,
employeeId?: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<FilterGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/filters',
            query: {
                'Type': type,
                'EmployeeId': employeeId,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Filter Success
     * @throws ApiError
     */
    public static postV1Filters({
requestBody,
}: {
requestBody?: Filter,
}): CancelablePromise<Filter> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/filters',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Filter Success
     * @throws ApiError
     */
    public static putV1Filters({
requestBody,
}: {
requestBody?: Filter,
}): CancelablePromise<Filter> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/filters',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Filter Success
     * @throws ApiError
     */
    public static getV1Filters1({
id,
}: {
id: number,
}): CancelablePromise<Filter> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/filters/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Filter Success
     * @throws ApiError
     */
    public static deleteV1Filters({
id,
}: {
id: number,
}): CancelablePromise<Filter> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/filters/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Filter Success
     * @throws ApiError
     */
    public static getV1Filters2({
key,
}: {
key: string,
}): CancelablePromise<Filter> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/filters/{key}',
            path: {
                'key': key,
            },
        });
    }

}
