import DataGrid from 'devextreme/ui/data_grid'
import DateBox from 'devextreme/ui/date_box'
import Form from 'devextreme/ui/form'
import TabPanel from 'devextreme/ui/tab_panel'

/**
 * Set DX defaults.
 */
export function defaultOptions() {
  DataGrid.defaultOptions({
    device: { deviceType: 'desktop' },
    options: {
      width: '100%',
      hoverStateEnabled: true,
      showBorders: false,
      showRowLines: true,
      showColumnLines: false,
      rowAlternationEnabled: true,
      columnHidingEnabled: false,
      allowColumnResizing: true,
      columnResizingMode: 'widget',
      stateStoring: {
        type: 'custom',
        savingTimeout: 1000,
      },
      loadPanel:
      {
        enabled: false,

        /* ??
        enabled: 'auto',
        indicatorSrc: '/logo.gif',
        shading: false,
        showIndicator: true,
        showPane: false,
        text: null,
*/
      },
      filterRow: {
        applyFilter: 'auto',
        visible: false,
        showAllText: null,
      },
      filterSyncEnabled: true,
      headerFilter: { visible: false },
      pager: {
        allowedPageSizes: [10, 25, 50, 100],
        displayMode: 'full',
        showPageSizeSelector: true,
        showNavButtons: true,
        showInfo: true,
        visible: true,
      },
      paging: {
        pageSize: 25,
      },
      selection: {
        selectAllMode: 'page',
      },
    },
  })

  TabPanel.defaultOptions({
    options:
        {
          selectedIndex: 0,
          loop: false,

          /* height: "100%", */
          animationEnabled: false,
          swipeEnabled: true,
        },
  })

  Form.defaultOptions({
    options: {
      colCount: 3,
      labelLocation: 'left',
      labelMode: 'floating',
      showValidationSummary: true,
      showColorAfterLabel: false,
    },
  })

  DateBox.defaultOptions({
    options: {
      // We force DateBox to use local time - this way when posting to server the date is still correct after UTC converting.
      // ?? dateSerializationFormat: 'yyyy-MM-dd',
    },
  })
}
