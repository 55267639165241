/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ForgotPassword } from '../models/ForgotPassword';
import type { Login } from '../models/Login';
import type { LoginMfa } from '../models/LoginMfa';
import type { LoginResponse } from '../models/LoginResponse';
import type { ResetPassword } from '../models/ResetPassword';
import type { UserData } from '../models/UserData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * @returns LoginResponse Success
     * @throws ApiError
     */
    public static postV1AuthLogin({
requestBody,
}: {
requestBody?: Login,
}): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/login',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns LoginResponse Success
     * @throws ApiError
     */
    public static postV1AuthLoginmfa({
requestBody,
}: {
requestBody?: LoginMfa,
}): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/loginmfa',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postV1AuthForgotPassword({
requestBody,
}: {
requestBody?: ForgotPassword,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/forgot-password',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postV1AuthResetPassword({
requestBody,
}: {
requestBody?: ResetPassword,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/auth/reset-password',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns UserData Success
     * @throws ApiError
     */
    public static getV1Auth(): CancelablePromise<UserData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/auth',
        });
    }

}
