import type { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'
import type { Router } from 'vue-router'
import { OpenAPI } from './api'
import Logger from './logger'
import { useAppStore } from '@/stores/appStore'

/**
 * Set mock headers
 * @param config
 */
function setMockHeader(config: InternalAxiosRequestConfig<unknown>) {
  // Set mock header?
  if (import.meta.env.VITE_MOCK_BAD_REQUEST === 'true')
    config.headers['Mock-BadRequest'] = 'true'
  else if (import.meta.env.VITE_MOCK_UNAUTHORIZED === 'true')
    config.headers['Mock-Unauthorized'] = 'true'
  else if (import.meta.env.VITE_MOCK_FORBIDDEN === 'true')
    config.headers['Mock-Forbidden'] = 'true'
  else if (import.meta.env.VITE_MOCK_NOT_FOUND === 'true')
    config.headers['Mock-NotFound'] = 'true'
}

/**
 * Handle response error
 * @param config
 */
function handleResponseError(router: Router, error: AxiosError) {
  if (error.response) {
    if (error.response.status === 401)
      redirectToErrorPage(router, '401')
    else if (error.response.status === 403)
      redirectToErrorPage(router, '403')
    else if (error.response.status === 404)
      redirectToErrorPage(router, '404')
    else
      Logger.error(`An error occurred: ${error.toJSON()}`)
  }
  else if (error.request) {
    redirectToErrorPage(router, 'network')
  }
  else {
    redirectToErrorPage(router, 'general', error.message)
  }
}

/**
 * Redirect to error page based on error type
 * @param router
 * @param error
 * @param message
 */
function redirectToErrorPage(router: Router, error: '401' | '403' | '404' | 'network' | 'general', message?: string) {
  if (error === '401')
    Logger.error('Unauthorized access detected. Redirecting to error page...')
  else if (error === '403')
    Logger.error('You do not have permission to access this resource. Redirecting to error page...')
  else if (error === '404')
    Logger.error('The requested resource was not found. Redirecting to error page...')
  else if (error === 'network')
    Logger.error('Network Error. Redirecting to error page...')
  else
    Logger.error(`General Error \'${message}\'. Redirecting to error page...`)

  router.push({
    path: '/error',
    query: { error },
  })
}

/**
 * Initalize axios interceptors
 * @param router
 */
export function initializeInterceptors(router: Router) {
  /**
   * Request interceptor
   */
  axios.interceptors.request.use(config => {
    // Get app store
    const appStore = useAppStore()

    // Set loading if not an event request?
    if (config.url?.includes('/events') === false)
      appStore.setLoading(true)

    // Set mock headers
    setMockHeader(config)

    return config
  }, (error: AxiosError) => {
    const appStore = useAppStore()

    appStore.setLoading(false)

    return Promise.reject(error)
  })

  /**
   * Response interceptor
   */
  axios.interceptors.response.use(response => {
    // Get app store
    const appStore = useAppStore()

    // Hide loading
    appStore.setLoading(false)

    return response
  }, (error: AxiosError) => {
    // Get app store
    const appStore = useAppStore()

    // Hide loading
    appStore.setLoading(false)

    // Handle response error
    handleResponseError(router, error)

    return Promise.reject(error)
  })
}

/**
 * Create axios instance with API base url and bearer token
 * @returns
 */
export const createApiAxios = (): AxiosInstance => axios.create({
  baseURL: OpenAPI.BASE,
  headers: {
    common: {
      Authorization: `Bearer ${OpenAPI.TOKEN}`,
    },
  },
})
