/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Employee } from '../models/Employee';
import type { EmployeeListItemGetResult } from '../models/EmployeeListItemGetResult';
import type { EmployeeLookupItemGetResult } from '../models/EmployeeLookupItemGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeesService {

    /**
     * @returns EmployeeListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Employees({
types,
statuses,
take,
skip,
search,
sortBy,
sortDesc,
}: {
types?: Array<number>,
statuses?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<EmployeeListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/employees',
            query: {
                'Types': types,
                'Statuses': statuses,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Employee Success
     * @throws ApiError
     */
    public static postV1Employees({
requestBody,
}: {
requestBody?: Employee,
}): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/employees',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Employee Success
     * @throws ApiError
     */
    public static putV1Employees({
requestBody,
}: {
requestBody?: Employee,
}): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/employees',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns EmployeeLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1EmployeesLookup({
types,
statuses,
employeeId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
types?: Array<number>,
statuses?: Array<number>,
employeeId?: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<EmployeeLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/employees/lookup',
            query: {
                'Types': types,
                'Statuses': statuses,
                'EmployeeId': employeeId,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Employee Success
     * @throws ApiError
     */
    public static getV1Employees1({
id,
}: {
id: number,
}): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/employees/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Employee Success
     * @throws ApiError
     */
    public static deleteV1Employees({
id,
}: {
id: number,
}): CancelablePromise<Employee> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/employees/{id}',
            path: {
                'id': id,
            },
        });
    }

}
