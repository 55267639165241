export interface AppConfig {
  apiUrl?: undefined | null | string
}

export function loadConfig(): Promise<AppConfig> {
  return fetch('/config.json')
    .then(response => {
      if (response.ok)
        return response.json()
      else
        return {}
    })
    .then(data => {
      // Handle the resolved data
      return data
    })
    .catch(() => {
      return {}
    })
}
