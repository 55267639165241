/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Event } from '../models/Event';
import type { Invoice } from '../models/Invoice';
import type { InvoiceLine } from '../models/InvoiceLine';
import type { InvoiceLineAutoAddModel } from '../models/InvoiceLineAutoAddModel';
import type { InvoiceLineGetResult } from '../models/InvoiceLineGetResult';
import type { InvoiceListItemGetResult } from '../models/InvoiceListItemGetResult';
import type { InvoiceReference } from '../models/InvoiceReference';
import type { InvoiceReferenceGetResult } from '../models/InvoiceReferenceGetResult';
import type { Invoices } from '../models/Invoices';
import type { TicketReference } from '../models/TicketReference';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvoicesService {

    /**
     * @returns InvoiceListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Invoices({
customerId,
customerIds,
types,
statuses,
includeTotalPrice,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
customerIds?: Array<number>,
types?: Array<number>,
statuses?: Array<number>,
includeTotalPrice?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<InvoiceListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/invoices',
            query: {
                'CustomerId': customerId,
                'CustomerIds': customerIds,
                'Types': types,
                'Statuses': statuses,
                'IncludeTotalPrice': includeTotalPrice,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Invoice Success
     * @throws ApiError
     */
    public static postV1Invoices({
requestBody,
}: {
requestBody?: Invoice,
}): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/invoices',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Invoice Success
     * @throws ApiError
     */
    public static putV1Invoices({
requestBody,
}: {
requestBody?: Invoice,
}): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/invoices',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Invoice Success
     * @throws ApiError
     */
    public static getV1Invoices1({
id,
includeCustomer,
includeLines,
includeReferences,
}: {
id: number,
includeCustomer?: boolean,
includeLines?: boolean,
includeReferences?: boolean,
}): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/invoices/{id}',
            path: {
                'id': id,
            },
            query: {
                'IncludeCustomer': includeCustomer,
                'IncludeLines': includeLines,
                'IncludeReferences': includeReferences,
            },
        });
    }

    /**
     * @returns Invoice Success
     * @throws ApiError
     */
    public static deleteV1Invoices({
id,
markAsDeleted,
}: {
id: number,
markAsDeleted?: boolean,
}): CancelablePromise<Invoice> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/invoices/{id}',
            path: {
                'id': id,
            },
            query: {
                'markAsDeleted': markAsDeleted,
            },
        });
    }

    /**
     * @returns Event Success
     * @throws ApiError
     */
    public static postV1InvoicesMany({
requestBody,
}: {
requestBody?: Invoices,
}): CancelablePromise<Event> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/invoices/many',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static putV1InvoicesMany({
ids,
status,
}: {
ids?: string,
status?: number,
}): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/invoices/many',
            query: {
                'ids': ids,
                'status': status,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static deleteV1InvoicesMany({
ids,
}: {
ids?: string,
}): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/invoices/many',
            query: {
                'ids': ids,
            },
        });
    }

    /**
     * @returns InvoiceLineGetResult Success
     * @throws ApiError
     */
    public static getV1InvoicesLines({
invoiceId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
invoiceId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<InvoiceLineGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/invoices/{invoiceId}/lines',
            path: {
                'invoiceId': invoiceId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns InvoiceLine Success
     * @throws ApiError
     */
    public static getV1InvoicesLines1({
id,
}: {
id: number,
}): CancelablePromise<InvoiceLine> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/invoices/lines/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns InvoiceLine Success
     * @throws ApiError
     */
    public static deleteV1InvoicesLines({
id,
}: {
id: number,
}): CancelablePromise<InvoiceLine> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/invoices/lines/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns InvoiceLine Success
     * @throws ApiError
     */
    public static postV1InvoicesLines({
requestBody,
}: {
requestBody?: InvoiceLine,
}): CancelablePromise<InvoiceLine> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/invoices/lines',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns InvoiceLine Success
     * @throws ApiError
     */
    public static putV1InvoicesLines({
requestBody,
}: {
requestBody?: InvoiceLine,
}): CancelablePromise<InvoiceLine> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/invoices/lines',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static postV1InvoicesLinesMany({
requestBody,
}: {
requestBody?: InvoiceLineAutoAddModel,
}): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/invoices/lines/many',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns InvoiceReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1InvoicesReferences({
invoiceId,
key,
take,
skip,
search,
sortBy,
sortDesc,
}: {
invoiceId: number,
key?: string,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<InvoiceReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/invoices/{invoiceId}/references',
            path: {
                'invoiceId': invoiceId,
            },
            query: {
                'key': key,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns InvoiceReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1InvoicesReferences1({
key,
take,
skip,
search,
sortBy,
sortDesc,
}: {
key?: string,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<InvoiceReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/invoices/references',
            query: {
                'key': key,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns InvoiceReference Success
     * @throws ApiError
     */
    public static postV1InvoicesReferences({
notify,
requestBody,
}: {
notify?: boolean,
requestBody?: InvoiceReference,
}): CancelablePromise<InvoiceReference> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/invoices/references',
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns InvoiceReference Success
     * @throws ApiError
     */
    public static putV1InvoicesReferences({
requestBody,
}: {
requestBody?: InvoiceReference,
}): CancelablePromise<InvoiceReference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/invoices/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns InvoiceReference Success
     * @throws ApiError
     */
    public static getV1InvoicesReferences2({
id,
}: {
id: number,
}): CancelablePromise<InvoiceReference> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/invoices/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TicketReference Success
     * @throws ApiError
     */
    public static deleteV1InvoicesReferences({
id,
}: {
id: number,
}): CancelablePromise<TicketReference> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/invoices/references/{id}',
            path: {
                'id': id,
            },
        });
    }

}
