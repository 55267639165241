

import __layout_0 from '/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/default.vue'
export const layouts = {
'admin': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/admin.vue'),
'blank': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/blank.vue'),
'default': __layout_0,
'support': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/support.vue'),
'components/Loading': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/Loading.vue'),
'components/NavBarI18n': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/NavBarI18n.vue'),
'components/NavbarShortcuts': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/NavbarShortcuts.vue'),
'components/NavbarThemeSwitcher': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/NavbarThemeSwitcher.vue'),
'components/NavSearchBar': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/NavSearchBar.vue'),
'components/admin/Footer': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/admin/Footer.vue'),
'components/admin/FooterComment': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/admin/FooterComment.vue'),
'components/admin/LayoutWithHorizontalNav': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/admin/LayoutWithHorizontalNav.vue'),
'components/admin/LayoutWithVerticalNav': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/admin/LayoutWithVerticalNav.vue'),
'components/admin/NavBarFilter': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/admin/NavBarFilter.vue'),
'components/admin/NavBarMenu': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/admin/NavBarMenu.vue'),
'components/admin/NavBarNotifications': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/admin/NavBarNotifications.vue'),
'components/admin/NavBarTitle': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/admin/NavBarTitle.vue'),
'components/support/Footer': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/support/Footer.vue'),
'components/support/LayoutWithHorizontalNav': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/support/LayoutWithHorizontalNav.vue'),
'components/support/LayoutWithVerticalNav': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/support/LayoutWithVerticalNav.vue'),
'components/support/NavBarMenu': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/support/NavBarMenu.vue'),
'components/support/NavBarNotifications': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/support/NavBarNotifications.vue'),
'components/support/NavBarTitle': () => import('/D:/Repos/SoftPartProjects/SmartMSP/Software/SmartMsp/SmartMsp.Web/src/layouts/components/support/NavBarTitle.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
