/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from '../models/Customer';
import type { CustomerListItemGetResult } from '../models/CustomerListItemGetResult';
import type { CustomerLookupItemGetResult } from '../models/CustomerLookupItemGetResult';
import type { CustomerReference } from '../models/CustomerReference';
import type { CustomerReferenceGetResult } from '../models/CustomerReferenceGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CustomersService {

    /**
     * @returns CustomerListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Customers({
types,
statuses,
take,
skip,
search,
sortBy,
sortDesc,
}: {
types?: Array<number>,
statuses?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<CustomerListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/customers',
            query: {
                'Types': types,
                'Statuses': statuses,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Customer Success
     * @throws ApiError
     */
    public static postV1Customers({
requestBody,
}: {
requestBody?: Customer,
}): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/customers',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Customer Success
     * @throws ApiError
     */
    public static putV1Customers({
requestBody,
}: {
requestBody?: Customer,
}): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/customers',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns CustomerLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1CustomersLookup({
types,
statuses,
customerId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
types?: Array<number>,
statuses?: Array<number>,
customerId?: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<CustomerLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/customers/lookup',
            query: {
                'Types': types,
                'Statuses': statuses,
                'CustomerId': customerId,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Customer Success
     * @throws ApiError
     */
    public static getV1Customers1({
id,
includeMainCustomer,
includeReferences,
}: {
id: number,
includeMainCustomer?: boolean,
includeReferences?: boolean,
}): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/customers/{id}',
            path: {
                'id': id,
            },
            query: {
                'IncludeMainCustomer': includeMainCustomer,
                'IncludeReferences': includeReferences,
            },
        });
    }

    /**
     * @returns Customer Success
     * @throws ApiError
     */
    public static deleteV1Customers({
id,
}: {
id: number,
}): CancelablePromise<Customer> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/customers/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CustomerReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1CustomersReferences({
customerId,
key,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId: number,
key?: string,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<CustomerReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/customers/{customerId}/references',
            path: {
                'customerId': customerId,
            },
            query: {
                'key': key,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns CustomerReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1CustomersReferences1({
key,
take,
skip,
search,
sortBy,
sortDesc,
}: {
key?: string,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<CustomerReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/customers/references',
            query: {
                'key': key,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns CustomerReference Success
     * @throws ApiError
     */
    public static postV1CustomersReferences({
requestBody,
}: {
requestBody?: CustomerReference,
}): CancelablePromise<CustomerReference> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/customers/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns CustomerReference Success
     * @throws ApiError
     */
    public static putV1CustomersReferences({
requestBody,
}: {
requestBody?: CustomerReference,
}): CancelablePromise<CustomerReference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/customers/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns CustomerReference Success
     * @throws ApiError
     */
    public static getV1CustomersReferences2({
id,
}: {
id: number,
}): CancelablePromise<CustomerReference> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/customers/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns CustomerReference Success
     * @throws ApiError
     */
    public static deleteV1CustomersReferences({
id,
}: {
id: number,
}): CancelablePromise<CustomerReference> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/customers/references/{id}',
            path: {
                'id': id,
            },
        });
    }

}
