import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', () => {
  // 👉 Reactives
  const loading = ref(false)

  // 👉 Function - Set loading (API Get, put, post, delete etc.)
  function setLoading(state: boolean): void {
    if (state) {
      loading.value = true
    }
    else {
      setTimeout(() => {
        loading.value = false
      }, 100)
    }
  }

  return {
    loading,
    setLoading,
  }
})
