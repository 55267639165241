/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dashboard } from '../models/Dashboard';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardService {

    /**
     * @returns Dashboard Success
     * @throws ApiError
     */
    public static getV1Dashboard({
key,
period,
from,
to,
}: {
key?: string,
period?: string,
from?: string,
to?: string,
}): CancelablePromise<Dashboard> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dashboard',
            query: {
                'key': key,
                'period': period,
                'from': from,
                'to': to,
            },
        });
    }

}
