/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Ticket } from '../models/Ticket';
import type { TicketActivity } from '../models/TicketActivity';
import type { TicketActivityAttachmentGetResult } from '../models/TicketActivityAttachmentGetResult';
import type { TicketActivityListItemGetResult } from '../models/TicketActivityListItemGetResult';
import type { TicketAttachment } from '../models/TicketAttachment';
import type { TicketAttachmentGetResult } from '../models/TicketAttachmentGetResult';
import type { TicketListItemGetResult } from '../models/TicketListItemGetResult';
import type { TicketReference } from '../models/TicketReference';
import type { TicketReferenceGetResult } from '../models/TicketReferenceGetResult';
import type { TicketsEditParameters } from '../models/TicketsEditParameters';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TicketsService {

    /**
     * @returns TicketListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Tickets({
customerId,
customerIds,
contactId,
contactIds,
employeeId,
employeeIds,
types,
statuses,
priorites,
isChanged,
isDeleted,
isCustomerNull,
isContactNull,
isEmployeeNull,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
customerIds?: Array<number>,
contactId?: number,
contactIds?: Array<number>,
employeeId?: number,
employeeIds?: Array<number>,
types?: Array<number>,
statuses?: Array<number>,
priorites?: Array<number>,
isChanged?: boolean,
isDeleted?: boolean,
isCustomerNull?: boolean,
isContactNull?: boolean,
isEmployeeNull?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TicketListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets',
            query: {
                'CustomerId': customerId,
                'CustomerIds': customerIds,
                'ContactId': contactId,
                'ContactIds': contactIds,
                'EmployeeId': employeeId,
                'EmployeeIds': employeeIds,
                'Types': types,
                'Statuses': statuses,
                'Priorites': priorites,
                'IsChanged': isChanged,
                'IsDeleted': isDeleted,
                'IsCustomerNull': isCustomerNull,
                'IsContactNull': isContactNull,
                'IsEmployeeNull': isEmployeeNull,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns Ticket Success
     * @throws ApiError
     */
    public static postV1Tickets({
notify,
requestBody,
}: {
notify?: boolean,
requestBody?: Ticket,
}): CancelablePromise<Ticket> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tickets',
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Ticket Success
     * @throws ApiError
     */
    public static putV1Tickets({
requestBody,
}: {
requestBody?: Ticket,
}): CancelablePromise<Ticket> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/tickets',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns Ticket Success
     * @throws ApiError
     */
    public static getV1Tickets1({
id,
includeCustomer,
includeContact,
includeEmployee,
includeActivities,
includeAttachments,
includeTimeEntries,
includeReferences,
}: {
id: number,
includeCustomer?: boolean,
includeContact?: boolean,
includeEmployee?: boolean,
includeActivities?: boolean,
includeAttachments?: boolean,
includeTimeEntries?: boolean,
includeReferences?: boolean,
}): CancelablePromise<Ticket> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets/{id}',
            path: {
                'id': id,
            },
            query: {
                'IncludeCustomer': includeCustomer,
                'IncludeContact': includeContact,
                'IncludeEmployee': includeEmployee,
                'IncludeActivities': includeActivities,
                'IncludeAttachments': includeAttachments,
                'IncludeTimeEntries': includeTimeEntries,
                'IncludeReferences': includeReferences,
            },
        });
    }

    /**
     * @returns Ticket Success
     * @throws ApiError
     */
    public static deleteV1Tickets({
id,
markAsDeleted,
}: {
id: number,
markAsDeleted?: boolean,
}): CancelablePromise<Ticket> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/tickets/{id}',
            path: {
                'id': id,
            },
            query: {
                'markAsDeleted': markAsDeleted,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static getV1TicketsCalendar({
id,
}: {
id: number,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets/{id}/calendar',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static putV1TicketsMerge({
parentId,
childIds,
}: {
parentId?: number,
childIds?: string,
}): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/tickets/merge',
            query: {
                'parentId': parentId,
                'childIds': childIds,
            },
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static putV1TicketsMany({
requestBody,
}: {
requestBody?: TicketsEditParameters,
}): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/tickets/many',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns number Success
     * @throws ApiError
     */
    public static deleteV1TicketsMany({
ids,
markAsDeleted,
}: {
ids?: string,
markAsDeleted?: boolean,
}): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/tickets/many',
            query: {
                'ids': ids,
                'markAsDeleted': markAsDeleted,
            },
        });
    }

    /**
     * @returns TicketActivityListItemGetResult Success
     * @throws ApiError
     */
    public static getV1TicketsActivities({
ticketId,
types,
isDeleted,
take,
skip,
search,
sortBy,
sortDesc,
}: {
ticketId: number,
types?: Array<number>,
isDeleted?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TicketActivityListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets/{ticketId}/activities',
            path: {
                'ticketId': ticketId,
            },
            query: {
                'Types': types,
                'IsDeleted': isDeleted,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns TicketActivityAttachmentGetResult Success
     * @throws ApiError
     */
    public static getV1TicketsActivitiesAttachments({
ticketId,
ticketActivitiyId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
ticketId: number,
ticketActivitiyId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TicketActivityAttachmentGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets/{ticketId}/activities/{ticketActivitiyId}/attachments',
            path: {
                'ticketId': ticketId,
                'ticketActivitiyId': ticketActivitiyId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns TicketActivity Success
     * @throws ApiError
     */
    public static getV1TicketsActivities1({
id,
includeContact,
includeEmployee,
includeAttachments,
includeTimeEntries,
}: {
id: number,
includeContact?: boolean,
includeEmployee?: boolean,
includeAttachments?: boolean,
includeTimeEntries?: boolean,
}): CancelablePromise<TicketActivity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets/activities/{id}',
            path: {
                'id': id,
            },
            query: {
                'IncludeContact': includeContact,
                'IncludeEmployee': includeEmployee,
                'IncludeAttachments': includeAttachments,
                'IncludeTimeEntries': includeTimeEntries,
            },
        });
    }

    /**
     * @returns TicketActivity Success
     * @throws ApiError
     */
    public static deleteV1TicketsActivities({
id,
}: {
id: number,
}): CancelablePromise<TicketActivity> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/tickets/activities/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TicketActivity Success
     * @throws ApiError
     */
    public static postV1TicketsActivities({
notify,
requestBody,
}: {
notify?: boolean,
requestBody?: TicketActivity,
}): CancelablePromise<TicketActivity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tickets/activities',
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TicketActivity Success
     * @throws ApiError
     */
    public static putV1TicketsActivities({
requestBody,
}: {
requestBody?: TicketActivity,
}): CancelablePromise<TicketActivity> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/tickets/activities',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TicketAttachmentGetResult Success
     * @throws ApiError
     */
    public static getV1TicketsAttachments({
ticketId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
ticketId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TicketAttachmentGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets/{ticketId}/attachments',
            path: {
                'ticketId': ticketId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns TicketAttachment Success
     * @throws ApiError
     */
    public static getV1TicketsAttachments1({
id,
}: {
id: string,
}): CancelablePromise<TicketAttachment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets/attachments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TicketAttachment Success
     * @throws ApiError
     */
    public static deleteV1TicketsAttachments({
id,
}: {
id: string,
}): CancelablePromise<TicketAttachment> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/tickets/attachments/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TicketAttachment Success
     * @throws ApiError
     */
    public static postV1TicketsAttachments({
notify,
requestBody,
}: {
notify?: boolean,
requestBody?: TicketAttachment,
}): CancelablePromise<TicketAttachment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tickets/attachments',
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TicketAttachment Success
     * @throws ApiError
     */
    public static putV1TicketsAttachments({
requestBody,
}: {
requestBody?: TicketAttachment,
}): CancelablePromise<TicketAttachment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/tickets/attachments',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TicketReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1TicketsReferences({
ticketId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
ticketId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TicketReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets/{ticketId}/references',
            path: {
                'ticketId': ticketId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns TicketReference Success
     * @throws ApiError
     */
    public static getV1TicketsReferences1({
id,
}: {
id: number,
}): CancelablePromise<TicketReference> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/tickets/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TicketReference Success
     * @throws ApiError
     */
    public static deleteV1TicketsReferences({
id,
}: {
id: number,
}): CancelablePromise<TicketReference> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/tickets/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TicketReference Success
     * @throws ApiError
     */
    public static postV1TicketsReferences({
notify,
requestBody,
}: {
notify?: boolean,
requestBody?: TicketReference,
}): CancelablePromise<TicketReference> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/tickets/references',
            query: {
                'notify': notify,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TicketReference Success
     * @throws ApiError
     */
    public static putV1TicketsReferences({
requestBody,
}: {
requestBody?: TicketReference,
}): CancelablePromise<TicketReference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/tickets/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
