import { setupLayouts } from 'virtual:generated-layouts'
import type { RouteLocation, RouteLocationNormalized } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import { ability } from '@/abilities/appAbility'
import { useAuthStore } from '@/stores/authStore'
import { useFilterStore } from '@/stores/filterStore'
import { isContact, isEmployee } from '@/utils/identity'
import Logger from '@/utils/logger'
import type { FilterMeta } from '@/utils/models/meta'
import routes from '~pages'

/**
 * Handle redirect '/'
 * @param to
 * @returns
 */
function redirectRoot(to: RouteLocation) {
  const authStore = useAuthStore()
  if (authStore.isLoggedIn) {
    const userData = authStore.userData

    if (userData?.employeeId)
      return { name: 'admin-dashboard' }
    if (userData?.contactId)
      return { name: 'support-tickets' }
  }

  return { name: 'login', query: to.query }
}

/**
 * Handle redirect '/redirect/element/{id}' (Replace redirect to correct url based on user employee/contact)
 * @param to
 * @returns
 */
function redirectElement(to: RouteLocation) {
  const authStore = useAuthStore()
  if (authStore.isLoggedIn) {
    const userData = authStore.userData

    const { element, id } = to.params

    if (isEmployee(userData))
      return { ...to, path: `/admin/${element}/${id}` }
    if (isContact(userData))
      return { ...to, path: `/support/${element}/${id}` }
  }

  return { name: 'login', query: to.query }
}

/**
 * Determine if page is restricted
 * @param to
 * @returns
 */
function isRestricted(to: RouteLocationNormalized) {
  return (to.meta.action !== undefined) && (to.meta.subject !== undefined)
}

/**
 * Determine if we can navigate
 * @param to
 * @returns
 */
function canNavigate(to: RouteLocationNormalized) {
  // @ts-expect-error We should allow passing string | undefined to can because for admin ability we omit defining action & subject
  return to.matched.some(route => ability.can(route.meta.action, route.meta.subject))
}

/**
 * Handle filters.
 * @param to
 */
async function handleFilter(to: RouteLocation) {
  const filterStore = useFilterStore()

  // Get filter meta from route
  const meta = to.meta?.filter as FilterMeta

  // Set filter type
  await filterStore.setFilterType(meta?.type)
}

const router = createRouter({
  // ?? base: process.env.BASE_URL,
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [

    // 👉 Root path
    {
      path: '/',
      redirect: to => redirectRoot(to),
    },

    // 👉 Redirect path
    {
      path: '/redirect/:element?/:id',
      redirect: to => redirectElement(to),
    },

    // 👉 Not-found path
    {
      path: '/:pathMatch(.*)*',
      redirect: () => ({ path: '/error', query: { error: '404' } }),
    },

    ...setupLayouts(routes),
  ],
})

/**
 * Handle before each route.
 *
 * Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
router.beforeEach(async to => {
  const authStore = useAuthStore()
  try {
    if (authStore.isLoggedIn) {
      const userData = authStore.userData

      const isAdminPage = to.name?.toString().startsWith('admin-') || to.path?.toString().startsWith('/admin')

      const isSupportPage = to.name?.toString().startsWith('support-') || to.path.toString().startsWith('/support')

      if (isAdminPage && !isEmployee(userData))
        return { name: 'not-authorized' }

      if (isSupportPage && !isContact(userData))
        return { name: 'not-authorized' }

      if (!isRestricted(to) || canNavigate(to)) {
        // Handle filters
        await handleFilter(to)

        if (to.meta.redirectIfLoggedIn)
          return '/'
      }
      else {
        return { name: 'not-authorized' }
      }
    }
    else {
      if ((to.name !== 'login') && (to.name !== 'forgot-password') && (to.name !== 'reset-password'))
        return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
    }
  }
  catch (e) {
    Logger.error('Routing failed', e)
  }
})

export { router }
