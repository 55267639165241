import type { NavGroup } from '@layouts/types'
import { ability } from '@/abilities/appAbility'

/**
 * Returns ability result
 */
export const canViewNavMenu = (action: string | undefined, subject: string | undefined) => {
  // @ts-expect-error We will get TS error in below line because we aren't using $can in component instance
  return ability.can(action, subject)
}

/**
 * Check if user can view item based on it's ability
 * Based on item's action and subject & Hide group if all of it's children are hidden
 * @param {Object} item navigation object item
 */
export const canViewNavMenuGroup = (item: NavGroup) => {
  // @ts-expect-error We should allow passing string | undefined to can because for admin ability we omit defining action & subject
  const hasAnyVisibleChild = item.children.some(i => ability.can(i.action, i.subject))

  // If subject and action is defined in item => Return based on children visibility (Hide group if no child is visible)
  // Else check for ability using provided subject and action along with checking if has any visible child
  if (!(item.action && item.subject))
    return hasAnyVisibleChild

  // @ts-expect-error We should allow passing string | undefined to can because for admin ability we omit defining action & subject
  return ability.can(item.action, item.subject) && hasAnyVisibleChild
}
