/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimeEntry } from '../models/TimeEntry';
import type { TimeEntryListItemGetResult } from '../models/TimeEntryListItemGetResult';
import type { TimeEntryLookupItemGetResult } from '../models/TimeEntryLookupItemGetResult';
import type { TimeEntrySummary } from '../models/TimeEntrySummary';
import type { TimeEntryType } from '../models/TimeEntryType';
import type { TimeEntryTypeListItemGetResult } from '../models/TimeEntryTypeListItemGetResult';
import type { TimeEntryTypeLookupItemGetResult } from '../models/TimeEntryTypeLookupItemGetResult';
import type { TimeEntryTypeReference } from '../models/TimeEntryTypeReference';
import type { TimeEntryTypeReferenceGetResult } from '../models/TimeEntryTypeReferenceGetResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimeEntriesService {

    /**
     * @returns TimeEntryListItemGetResult Success
     * @throws ApiError
     */
    public static getV1Timeentries({
customerId,
employeeId,
ticketId,
projectId,
subscriptionId,
voucherId,
customerIds,
employeeIds,
typeIds,
isCustomerNull,
isInvoiced,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
employeeId?: number,
ticketId?: number,
projectId?: number,
subscriptionId?: number,
voucherId?: number,
customerIds?: Array<number>,
employeeIds?: Array<number>,
typeIds?: Array<number>,
isCustomerNull?: boolean,
isInvoiced?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TimeEntryListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/timeentries',
            query: {
                'CustomerId': customerId,
                'EmployeeId': employeeId,
                'TicketId': ticketId,
                'ProjectId': projectId,
                'SubscriptionId': subscriptionId,
                'VoucherId': voucherId,
                'CustomerIds': customerIds,
                'EmployeeIds': employeeIds,
                'TypeIds': typeIds,
                'IsCustomerNull': isCustomerNull,
                'IsInvoiced': isInvoiced,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns TimeEntry Success
     * @throws ApiError
     */
    public static postV1Timeentries({
requestBody,
}: {
requestBody?: TimeEntry,
}): CancelablePromise<TimeEntry> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/timeentries',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TimeEntry Success
     * @throws ApiError
     */
    public static putV1Timeentries({
requestBody,
}: {
requestBody?: TimeEntry,
}): CancelablePromise<TimeEntry> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/timeentries',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TimeEntryLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1TimeentriesLookup({
customerId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
customerId?: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TimeEntryLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/timeentries/lookup',
            query: {
                'CustomerId': customerId,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns TimeEntry Success
     * @throws ApiError
     */
    public static getV1Timeentries1({
id,
}: {
id: number,
}): CancelablePromise<TimeEntry> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/timeentries/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TimeEntry Success
     * @throws ApiError
     */
    public static deleteV1Timeentries({
id,
}: {
id: number,
}): CancelablePromise<TimeEntry> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/timeentries/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TimeEntrySummary Success
     * @throws ApiError
     */
    public static getV1TimeentriesSummary({
customerId,
employeeId,
ticketId,
projectId,
voucherId,
subscriptionId,
typeIds,
}: {
customerId?: number,
employeeId?: number,
ticketId?: number,
projectId?: number,
voucherId?: number,
subscriptionId?: number,
typeIds?: Array<number>,
}): CancelablePromise<TimeEntrySummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/timeentries/summary',
            query: {
                'CustomerId': customerId,
                'EmployeeId': employeeId,
                'TicketId': ticketId,
                'ProjectId': projectId,
                'VoucherId': voucherId,
                'SubscriptionId': subscriptionId,
                'TypeIds': typeIds,
            },
        });
    }

    /**
     * @returns TimeEntryTypeListItemGetResult Success
     * @throws ApiError
     */
    public static getV1TimeentriesTypes({
statuses,
isInvoiceable,
take,
skip,
search,
sortBy,
sortDesc,
}: {
statuses?: Array<number>,
isInvoiceable?: boolean,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TimeEntryTypeListItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/timeentries/types',
            query: {
                'Statuses': statuses,
                'IsInvoiceable': isInvoiceable,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns TimeEntryType Success
     * @throws ApiError
     */
    public static postV1TimeentriesTypes({
requestBody,
}: {
requestBody?: TimeEntryType,
}): CancelablePromise<TimeEntryType> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/timeentries/types',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TimeEntryType Success
     * @throws ApiError
     */
    public static putV1TimeentriesTypes({
requestBody,
}: {
requestBody?: TimeEntryType,
}): CancelablePromise<TimeEntryType> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/timeentries/types',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TimeEntryTypeLookupItemGetResult Success
     * @throws ApiError
     */
    public static getV1TimeentriesTypesLookup({
statuses,
take,
skip,
search,
sortBy,
sortDesc,
}: {
statuses?: Array<number>,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TimeEntryTypeLookupItemGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/timeentries/types/lookup',
            query: {
                'Statuses': statuses,
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns TimeEntryType Success
     * @throws ApiError
     */
    public static getV1TimeentriesTypes1({
id,
includeReferences,
}: {
id: number,
includeReferences?: boolean,
}): CancelablePromise<TimeEntryType> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/timeentries/types/{id}',
            path: {
                'id': id,
            },
            query: {
                'IncludeReferences': includeReferences,
            },
        });
    }

    /**
     * @returns TimeEntryType Success
     * @throws ApiError
     */
    public static deleteV1TimeentriesTypes({
id,
}: {
id: number,
}): CancelablePromise<TimeEntryType> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/timeentries/types/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TimeEntryTypeReferenceGetResult Success
     * @throws ApiError
     */
    public static getV1TimeentriesTypesReferences({
timeEntryTypeId,
take,
skip,
search,
sortBy,
sortDesc,
}: {
timeEntryTypeId: number,
take?: number,
skip?: number,
search?: string,
sortBy?: string,
sortDesc?: boolean,
}): CancelablePromise<TimeEntryTypeReferenceGetResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/timeentries/types/{timeEntryTypeId}/references',
            path: {
                'timeEntryTypeId': timeEntryTypeId,
            },
            query: {
                'Take': take,
                'Skip': skip,
                'Search': search,
                'SortBy': sortBy,
                'SortDesc': sortDesc,
            },
        });
    }

    /**
     * @returns TimeEntryTypeReference Success
     * @throws ApiError
     */
    public static getV1TimeentriesTypesReferences1({
id,
}: {
id: number,
}): CancelablePromise<TimeEntryTypeReference> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/timeentries/types/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TimeEntryTypeReference Success
     * @throws ApiError
     */
    public static deleteV1TimeentriesTypesReferences({
id,
}: {
id: number,
}): CancelablePromise<TimeEntryTypeReference> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/timeentries/types/references/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns TimeEntryTypeReference Success
     * @throws ApiError
     */
    public static postV1TimeentriesTypesReferences({
requestBody,
}: {
requestBody?: TimeEntryTypeReference,
}): CancelablePromise<TimeEntryTypeReference> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/timeentries/types/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

    /**
     * @returns TimeEntryTypeReference Success
     * @throws ApiError
     */
    public static putV1TimeentriesTypesReferences({
requestBody,
}: {
requestBody?: TimeEntryTypeReference,
}): CancelablePromise<TimeEntryTypeReference> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/timeentries/types/references',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
