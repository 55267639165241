/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppStatus } from '../models/AppStatus';
import type { Comment } from '../models/Comment';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppService {

    /**
     * @returns AppStatus Success
     * @throws ApiError
     */
    public static getV1App(): CancelablePromise<AppStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/app',
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postV1AppComment({
requestBody,
}: {
requestBody?: Comment,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/app/comment',
            body: requestBody,
            mediaType: 'application/json-patch+json; x-api-version=1.0',
        });
    }

}
